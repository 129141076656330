<template>
  <div id="recruit" class="contents">
    <h1 title="採用情報">RECRUIT</h1>
      <div class="intro">
        <h2>共に”感動体験を創る”</h2>
        <p>単に仕様通りに作るだけではなく、<br class="sp">エンドユーザーにどう響くか、どう届くかを<br>共に考え抜くことのできるメンバーを募集しています。</p>
      </div>
      <div class="contents">

          <section>
          <h2 title="募集概要">OVERVIEW</h2>
          <div class="content">
            <div class="overview">
              <dl>
                <dt data-aos="fade-right">募集職種</dt>
                <dd data-aos="fade-right" data-aos-delay=300>プログラマー</dd>
                <dt data-aos="fade-right">主な仕事内容</dt>
                <dd data-aos="fade-right" data-aos-delay=300>
                  <ul>
                    <li>PC、スマートフォン向けアプリ開発</li>
                    <li>イベント展示用デジタルコンテンツ制作</li>
                    <li>VRコンテンツ制作</li>
                    <li>WebGLを使った3Dコンテンツ制作</li>
                    <li>センサーを使ったゲームコンテンツ制作</li>
                  </ul>
                </dd>
                <dt data-aos="fade-right">メッセージ</dt>
                <dd data-aos="fade-right" data-aos-delay=300>ゲーム制作をメインに、ゲームエンジンUnityを使用したコンテンツ制作を行っています。<br>ゲーム制作に関わった事のある方は歓迎ですが、未経験な方でも自ら学び取る方であれば経験は問いません。<br>※未経験の場合、ポートフォリオ（ゲームやイラスト等）を必須とさせていただきます。</dd>
                <dt data-aos="fade-right">特徴</dt>
                <dd data-aos="fade-right" data-aos-delay=300>自分らしく働けることを大切にしています。<br>状況によって在宅でのリモートワークや、勤務時間など調整できますので、ご相談ください。</dd>
                <dt data-aos="fade-right">必要なスキル</dt>
                <dd data-aos="fade-right" data-aos-delay=300>UnityやUnrealEngine等でゲーム開発に携わったことがある方、又は自主制作でゲーム開発を行ったことがある方</dd>
                <dt data-aos="fade-right">歓迎するスキル</dt>
                <dd data-aos="fade-right" data-aos-delay=300>Unity、UnrealEngine、C#、C++、3DCG、Photoshop、Illustrator</dd>
                <dt data-aos="fade-right">雇用形態</dt>
                <dd data-aos="fade-right" data-aos-delay=300>正社員</dd>
                <dt data-aos="fade-right">勤務地</dt>
                <dd data-aos="fade-right" data-aos-delay=300>広島市中区本川町</dd>
                <dt data-aos="fade-right">転勤の有無</dt>
                <dd data-aos="fade-right" data-aos-delay=300>なし</dd>
                <dt data-aos="fade-right">試用期間</dt>
                <dd data-aos="fade-right" data-aos-delay=300>３ヶ月</dd>
                <dt data-aos="fade-right">給与</dt>
                <dd data-aos="fade-right" data-aos-delay=300>月 200,000円〜340,000円</dd>
                <dt data-aos="fade-right">通勤手当</dt>
                <dd data-aos="fade-right" data-aos-delay=300>実費支給（上限あり）</dd>
                <dt data-aos="fade-right">昇給</dt>
                <dd data-aos="fade-right" data-aos-delay=300>昇給制度あり</dd>
                <dt data-aos="fade-right">賞与</dt>
                <dd data-aos="fade-right" data-aos-delay=300>賞与制度あり</dd>
                <dt data-aos="fade-right">就業時間</dt>
                <dd data-aos="fade-right" data-aos-delay=300>10時00分〜19時00分</dd>
                <dt data-aos="fade-right">休日</dt>
                <dd data-aos="fade-right" data-aos-delay=300>土曜日，日曜日，祝日(週休二日制)</dd>
              </dl>
            </div>
          </div>
          </section>
      </div>

      <ToTop />
      <ToContact />
  </div>
</template>
<script>
export default {
  mounted: function () {
    this.$nextTick(function () {
      this.initGsap(this.$isMobile())
    })
  },
  metaInfo: {
    title: '採用情報',
    bodyAttrs: {
      id: 'pg-recruit',
      class: 'cover'
    },
    meta: [
      { name: 'description', content: '少数精鋭の為、指示を待つのではなく、状況に合わせ臨機応変に自ら対応できることができる方を募集しています。' }
    ],
    link: [
      { rel: 'canonical', href: 'https://sorashiro.com/recruit/' }
    ]
  },
  beforeDestroy: function () {
    this.removeGsap()
  }
}
</script>
